<template>
    <v-card elevation="5" v-if="isVisible" class="survey-container-card py-6 pt-3">
        <v-card-title class="text-center">
            <v-spacer></v-spacer>
            <span v-if="!succesfullySent">
                {{ title }}
            </span>
            <v-spacer></v-spacer>
            <!-- This pushes the button to the right -->
            <v-btn icon @click="closeCard" class="position-absolute" style="right: 21px">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="w-100 pt-1 pb-6">
            <QuestionSwitcher v-if="!succesfullySent" :question="currentQuestionData" @on-answer="onQuestionAnswer" />

            <!-- Successful sent message -->
            <v-row v-else>
                <v-col cols="12" class="text-center">
                    <v-icon color="amber" size="100">mdi-check-circle</v-icon>
                    <h3 class="mt-4">¡Muchas gracias por ayudarnos a mejorar!</h3>
                    <p class="mt-4">Hemos recibido tu respuesta.</p>

                    <v-btn
                        color="amber"
                        elevation="0"
                        @click="closeCard"
                    >
                        Cerrar
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions v-if="!succesfullySent">
            <v-row align="center" justify="space-around">
                <v-btn
                    small
                    text
                    elevation="0"
                    :disabled="!(currentQuestionIndex >= 1)"
                    @click="onPrevQuestion"
                >
                    Anterior
                </v-btn>
                <div class="body-2 ma-0 pa-0">
                    {{ currentQuestionIndex + 1 }}/{{ questions.length }}
                </div>

                <v-btn
                    small
                    text
                    elevation="0"
                    v-if="currentQuestionIndex !== questions.length - 1"
                    :disabled="!isNextAvailable"
                    @click="onNextQuestion"
                    :loading="isSending"
                >
                    Siguiente
                </v-btn>
                <v-btn
                    small
                    color="amber"
                    elevation="0"
                    v-else
                    :disabled="isSendAvailable"
                    @click="onSend"
                    :loading="isSending"
                >
                    Enviar
                </v-btn>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import QuestionSwitcher from '../questionSwitcher'
export default {
    name: 'SurveyContainer',
    components: {
        QuestionSwitcher,
    },
    props: {
        title: {
            default: 'Survey Container',
            required: true,
            type: String,
        },
        onClose: {
            default: () => {},
            required: false,
            type: Function,
        },
        isVisible: {
            default: true,
            required: false,
            type: Boolean,
        },
    },
    data() {
        return {
            isSending: false,
            succesfullySent: false,
            sentError: null
        }
    },
    computed: {
        ...mapState({
            teacherId: (state) => state.userData.user.id,
        }),
        ...mapState('survey', [
            'answers',
            'currentAnswer',
            'currentSurvey',
            'currentQuestion',
            'currentQuestionIndex',
        ]),
        questions() {
            return this.currentSurvey ? this.currentSurvey.questions : []
        },
        currentQuestionData() {
            return this.questions[this.currentQuestionIndex]
        },
        isSendAvailable() {
            if (
                (this.currentQuestionData.required && !this.currentAnswer) ||
                this.currentAnswer?.value === null ||
                this.currentAnswer?.value === undefined
            ) {
                return false
            }
            return this.currentQuestionIndex === this.questions.length - 1
        },
        isNextAvailable() {
            if (
                (this.currentQuestionData.required && !this.currentAnswer) ||
                this.currentAnswer === null ||
                this.currentAnswer === undefined
            ) {
                return false
            }
            return this.currentQuestionIndex === this.questions.length - 1 ? false : true
        },
    },
    methods: {
        ...mapMutations('survey', [
            'addAnswer',
            'prevQuestion',
            'nextQuestion',
            'setCurrentAnswer',
            'setCurrentQuestionIndex',
            'setCurrentSurvey',
            'error',
        ]),
        ...mapActions('survey', ['sendSurveyAnswers']),
        closeCard() {
            this.$emit('on-close')
        },
        async onNextQuestion() {
            this.addAnswer({ answer: this.currentAnswer })
            this.isSending = true
            await this.sendSurveyAnswers({ userId: this.teacherId })
            this.isSending = false
            this.nextQuestion()
        },
        onPrevQuestion() {
            this.prevQuestion()
        },
        onQuestionAnswer(value) {
            this.setCurrentAnswer({
                answer: { answer: value, questionId: this.currentQuestionData.id },
            })
        },
        async onSend() {
            try {
                this.isSending = true
                if(!this.currentAnswer) {
                    this.addAnswer({ answer: { answer: null, questionId: this.currentQuestionData.id } })
                } else {
                    this.addAnswer({ answer: this.currentAnswer  })
                }
                await this.sendSurveyAnswers({ userId: this.teacherId })
                this.succesfullySent = true
            } catch (err) {
                if (this.error) {
                    this.$modal.error(this.error)
                    this.sentError = this.error
                }
            } finally {
                this.isSending = false
            }
        },
    },
}
</script>

<style scoped>
.survey-container-card {
    z-index: 999;
    max-width: 450px;
}
</style>
