<template>
    <div
        class="rate-question w-100 d-flex flex-column align-items-center justfiy-content-center text-center"
    >
        <h3 class="body-1 mb-5">{{ question.question }}</h3>
        <v-textarea outlined :v-model="currentAnswer?.answer" @input="handleInputChange" :placeholder="question.placeholder"></v-textarea>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        question: {
            default: {},
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            userResponse: '',
        }
    },
    computed: {
        ...mapState('survey', [ 'currentAnswer' ])
    },
    watch: {
        userResponse(response) {
            this.$emit('on-answer', response)
        },
        currentAnswer() {
            this.userResponse = this.currentAnswer?.answer || ''
        }
    },
    methods: {
        handleInputChange(val) {
            this.userResponse = val
        }
     }
}
</script>
