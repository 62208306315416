<template>
    <component :is="currentQuestionComponent" @on-answer="onAnswer" :question="question"></component>
</template>

<script>
import { SurveyQuestionType } from '@/store/modules/Survey/types'
import RateQuestion from '../questions/questionRate'
import TextQuestion from '../questions/questionText'
export default {
    name: 'QuestionSwitcher',
    components: {
        RateQuestion,
        TextQuestion
    },
    props: {
        question: {
            default: {},
            required: true,
            type: Object
        },
    },
    computed: {
        currentQuestionComponent() {
            switch (this.question.questionType) {
                case SurveyQuestionType.RATE:
                    return 'RateQuestion'
                case SurveyQuestionType.TEXT:
                    return 'TextQuestion'
                default:
                    return 'RateQuestion'
            }
        }
    },
    methods: {
        onAnswer(answer) {
            this.$emit('on-answer', answer)
        }
    }
}
</script>