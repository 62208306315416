<template>
    <div class="rate-question w-100 d-flex flex-column align-items-center justfiy-content-center text-center">
      <h3 class="body-1 mb-5">{{ question.question }}</h3>
      <div class="ratings">
        <v-btn
          v-for="rating in ratings"
          :key="rating"
          :class="`${currentAnswer?.answer === rating ? 'selected' : ''} mb-2`"
          icon
          outlined
          small
          @click="selectRating(rating)">
          {{ rating }}
        </v-btn>
      </div>
      <v-row align="center" justify="space-between" class="px-5 mt-4">
          <p class="body-2">
            0 - {{ question.labels?.start || 'No es probable en absoluto' }}
          </p>
          <p class="body-2">
            10 - {{ question.labels?.end || 'Es muy probable' }}
          </p>
      </v-row>
    </div>
  </template>
  
  <script>
import { mapState } from 'vuex';
  export default {
    props: {
        question: {
            default: {},
            required: true,
            type: Object
        },
        onNextQuestion: {
            default: () => {},
            required: false,
            type: Function
        }
    },
    data() {
      return {
        selectedRating: null, // Holds the current selected rating
        ratings: Array.from({ length: 11 }, (_, i) => i)
      };
    },
    computed: {
      ...mapState('survey', ['currentAnswer'])
    },
    methods: {
      selectRating(rating) {
        this.$emit('on-answer', rating);
      }
    },
  }
  </script>
  
  <style scoped>
  .ratings button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
  }
  
  .ratings button.selected {
    background-color: #FFCA28;
    color: white;
  }
  </style>